@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/7B5766823ADCCC6E7.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/7B5766823ADCCC6E7.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/A01D10AEE6282713B.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/A01D10AEE6282713B.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/85BB350E3C5580832.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/85BB350E3C5580832.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/0F84D902CA2FCC756.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/0F84D902CA2FCC756.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/CBC65279534A9A135.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/CBC65279534A9A135.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/AE79AFC91F4679B7F.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/AE79AFC91F4679B7F.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney Cond';
  src: url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/46C9DC746FB6F93DB.woff2') format('woff2'),
    url('https://s3.ca-central-1.amazonaws.com/getloop.ca/cibc-fonts/46C9DC746FB6F93DB.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
