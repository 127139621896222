@import 'font.scss';
@import '_user_variables';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
  text-rendering: optimizelegibility;
}

.btn:focus,
button:focus {
  outline: none !important;
}

p {
  line-height: 1.7;
  font-weight: 300;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-position-x: calc(100% - 6px) !important;
  background-position-y: center !important;
}

.c-pointer {
  cursor: pointer;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 1.2rem !important;
}

.fs-08 {
  font-size: 0.875rem !important;
}

.fs-08-sm {
  font-size: 0.8125rem !important;
}
.fw-600 {
  font-weight: 600 !important;
}

.fwb {
  font-weight: 700 !important;
}
.fwn {
  font-weight: 400 !important;
}

.b-shadow-3d {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1);
}

.text-underline {
  text-decoration: underline;
}

.text-underline-i {
  text-decoration: underline !important;
}

// override input states
.custom-select:focus,
.form-control:focus {
  border: solid 2px $charcoal !important;
  box-shadow: none !important;
  outline: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border: solid 2px $cibc-burgundy;
  background: $lighter-red;
}

.form-control:disabled,
.form-control[readonly] {
  color: $darker-grey;
  border: solid 1px $dark-grey;
}

.btn:hover {
  box-shadow: none !important;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: $cibc-burgundy;
  color: #fff;
}

.outline-none {
  outline: none;
}
