@import '../styles/user_variables';
.root {
  color: #fff;
  overflow: hidden;
  box-shadow: 0px 2000px 0px 2000px $charcoal;
}

.lendingLoopLink {
  font-weight: 800;
  width: 320px;
  color: #492660 !important;
  font-size: 14px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 4px;
  margin: 32px 0;
  cursor: pointer;
  float: right;
}

.lendingLoopLink:hover {
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .lendingLoopLink {
    margin-top: 0;
  }
}
