.box {
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  background: #fff;
}

.forgotPasswordContainer {
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.boxMobile {
  max-width: 520px;
  background: #e3edf9;
  width: 100%;
  display: flex;
  margin: 0 auto;
  border-radius: 4px;
}

.input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  color: #152939;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 14px;
}

.input:disabled {
  color: #545454;
  background-color: #e1e4ea;
}

.inputLabel {
  color: #152939;
  font-size: 14px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 599px) {
  .input {
    font-size: 16px;
  }
}
