$cibc-primary: #c41f3e;
$cibc-burgundy: #8b1d41;

// All shades of grey
$light-grey: #f2f3f2;
$grey: #d9d9d6;
$mid-grey: #bbbcbc;
$dark-grey: #86888a;
$darker-grey: #606366;
$charcoal: #383b3e;
$light-pink: #faf1f3;

$lighter-red: #faf0f3;

$btn-padding-y: 12px;
$btn-padding-x: 30px;
$btn-font-size: 13px;
$btn-border-radius: 4px;
$btn-font-family: 'Whitney';
$btn-font-weight: 600;
$btn-line-height: 1.12;
$btn-box-shadow: none;
$btn-transition: none;

$input-height: 36px;
$input-padding-y: 0px;
$input-color: $charcoal;
$input-font-size: 13px;
$input-border-width: 1px;
$input-border-color: $darker-grey;
$input-border-radius: 1px;
$input-disabled-bg: $grey;

$h1-font-size: 32px;
$h2-font-size: 24px;
$h3-font-size: 22px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;

$body-bg: $light-grey;
$body-color: $charcoal;
$headings-font-family: 'Whitney', Helvetica, Arial, sans-serif;
$font-family-base: Arial, sans-serif;
